<template>
    <section class="view blog">
        <view-title v-bind:title="$t('message.blog.title')"></view-title>
        <div class="container py-5">
            <b-pagination class="pagination-marier mb-4"
                          align="right"
                          size="sm"
                          v-bind:total-rows="blog.length"
                          v-bind:per-page="itemPerPage"
                          v-model="currentPage">
            </b-pagination>

            <div>
                <article-item v-for="blog in paginatedBlogs"
                              v-bind:key="blog._id"
                              v-bind:article="blog"
                              v-bind:to="`/blog/${ blog._id }`">
                </article-item>
            </div>

            <b-pagination class="pagination-marier mt-4 mb-0"
                          align="right"
                          size="sm"
                          v-bind:total-rows="blog.length"
                          v-bind:per-page="itemPerPage"
                          v-model="currentPage">
            </b-pagination>
        </div>
    </section>
</template>

<script>
import {
    BPagination
} from "bootstrap-vue";

import ViewTitle from "../components/ViewTitle";
import ArticleItem from "@/components/ArticleItem";

export default {
    name: "Blogs",
    components: {
        ViewTitle,
        BPagination,
        ArticleItem,
    },
    metaInfo: {
        title: "網誌",
    },
    data () {
        return {
            isLoading: false,

            itemPerPage: 6,
            currentPage: 1
        }
    },
    computed: {
        blog () {
            return this.$store.state.mall.blog;
        },

        paginatedBlogs () {
            return this.blog
                .slice(
                    (this.currentPage - 1) * this.itemPerPage,
                    (this.currentPage) * this.itemPerPage
                );
        },

        locale () {
            return this.$i18n.locale;
        },
    },
    methods: {

    },
    async beforeMount () {
        this.isLoading = true;
        await this.$store.dispatch("mall/loadBlog");
        this.isLoading = false;
    },
    watch: {
        async "locale" () {
            this.isLoading = true;
            await this.$store.dispatch("mall/loadBlog");
            this.isLoading = false;
        },
    },
}
</script>

<style lang="scss" scoped>
.blog {
    color: rgba(113, 113, 113, 1);

    .is-200x200 {
        height: 200px;
        width: 200px;
    }

    article.piece-news {
        border-bottom: solid 1px rgba(113, 113, 113, 1);
    }
}
</style>
